import * as i from 'types';
import React from 'react';

import { useLocation } from '@reach/router';
import { graphql, navigate } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { Heading, Paragraph } from 'common/typography';

import {
  HeaderImageWrapper,
  HeaderWithImageButton,
  HeaderWithImageContainer,
  HeaderWithImageSubtitle,
  HeaderWithImageUspList,
  HeaderWithImageUspListItem,
  HeaderWithImageUspListItemIcon,
  HeaderwithImageImage,
} from './styled';

export const HeaderWithImage = ({
  content: { header },
}: HeaderWithImageProps) => {
  const location = useLocation();
  const isHome = location.pathname === '/';
  const scaledImage = header.headerImage && getImage(header.headerImage);

  const startTool = (route: string) => {
    navigate(`${!isHome ? '/' : ''}${route}`);
  };

  return (
    <HeaderWithImageContainer>
      <div>
        <HeaderWithImageSubtitle newSize={2} weight={500}>
          {header.subtitle}
        </HeaderWithImageSubtitle>
        <Heading variant="orange" margin="0" marginDesktop="0">
          {header.title}
        </Heading>
        <Paragraph margin="16px 0 32px">{header.description}</Paragraph>
        <HeaderWithImageUspList>
          {header?.usps?.map((usp) => {
            return (
              <HeaderWithImageUspListItem key={usp.title}>
                <HeaderWithImageUspListItemIcon
                  src={usp.uspImage?.file.url}
                  alt={usp.uspImage?.title || ''}
                />
                <Paragraph margin="0">{usp.title}</Paragraph>
              </HeaderWithImageUspListItem>
            );
          })}
        </HeaderWithImageUspList>
        {header?.ctaS.map((cta, index) => {
          return (
            <HeaderWithImageButton
              key={`${cta.title}_${index}`}
              id={cta.clickId && cta.clickId}
              onClick={() => startTool(cta.route)}
            >
              {cta.title}
            </HeaderWithImageButton>
          );
        })}
      </div>
      <HeaderImageWrapper>
        {header.headerImage && scaledImage && (
          <HeaderwithImageImage
            image={scaledImage}
            alt={header.headerImage.title}
          />
        )}
      </HeaderImageWrapper>
    </HeaderWithImageContainer>
  );
};

type HeaderWithImageProps = {
  content: {
    header: i.ComponentHomeHeaderBlock;
  };
};

export const headerBlocksQuery = graphql`
  fragment HeaderBlocks on ContentfulComponentHomeHeaderBlocks {
    __typename
    id
    header {
      __typename
      id
      title
      subtitle
      description
      usps {
        title
        uspImage {
          title
          file {
            url
          }
        }
      }
      ctaS {
        title
        trackingId
        clickId
        route
      }
      headerImage {
        title
        file {
          url
        }
        gatsbyImageData(width: 1112, height: 928)
      }
    }
  }
`;
